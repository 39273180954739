.item {
  width: 250px;
  height: 350px;
  border: 1px solid gray;
  margin: 0.5rem;
  position: relative;
  float: left;
  a {
    display: block;
    text-decoration: none;
  }
  .img-holder {
    width: 250px;
    height: 250px;
    position: relative;
    background-color: #eaeaea;
    margin-bottom: 0.4rem;
    img {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
  p {
    font-size: 0.8rem;
    line-height: 1.4;
    margin: 0;
    margin-left: 0.3rem;
  }
}