html.edit-collection {
  form .input-group {
    display: none;
  }
  #all-works {

    .item.inCollection {
      a::after {
        content: 'added. click to remove';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 40% 30%;
        background-color: rgba(255,255,255,0.6);
        color: black;
        text-align: center;
      }
    }

    .item:not(.inCollection) {
      a:hover::after {
        content: 'add';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 50% 30%;
        background-color: rgba(0,0,0,0.7);
        color: white;
        text-align: center;
      }
    }


  }

  #collection {
    .item {
      a:hover::after {
        content: 'remove';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 50% 30%;
        background-color: rgba(0,0,0,0.7);
        color: white;
        text-align: center;
      }
      input.collPrice {
        position: absolute;
        bottom: 0px;
        z-index: 100;
      }
    }
  }
}