html.print-authentication {
  #inner-wrapper { overflow: scroll;}
  .img-wrapper img {
    opacity: 1;
    width: auto;
    height: auto;
    margin: 0.5rem 0;
  }
  .warning { color: darkred; }
  .letterhead { display: none;}
  .form {
    margin: 1rem 0 3rem 0;
  }
  input {
    transform: scale(1.5);
    transform-origin: bottom;
    margin: 0 10px;
  }
  label {
    font-size: 1.4rem;
  }
  button {
    //background-color: $blue;
    color: white;
    border: none;
    -webkit-appearance: none;
    margin-left: 20px;
    position: relative;
    top: -1px;
    padding: 7px 30px;
    line-height: 1;
    font-size: 1.2rem;
    //&:hover { background-color: $blue-hover; }
  }
  table td {font-weight: 300;}
  @media print {
    background-color: transparent;
    overflow: visible;
    body {
      height: 11.67in; 
      width: 8.27in; 
      overflow: visible;
      background-color: transparent;
    }
    nav, .warning, label, input, .letterhead, button { display: none; }
    #admin-wrapper { padding: 100px 80px 0px 80px; overflow-y:visible;}
    #admin-wrapper #inner-wrapper { height: 100%; padding: 0; overflow-y:visible;}
    h4 { text-align: center; font-size: 1rem; font-weight: 400; margin-bottom: 2rem;}
    p, td { font-size: 0.8rem; font-weight: 300; }
    p.text {margin-bottom: 0;}
    .img-wrapper img {
      max-width: 560px;
      max-height: 380px;
      padding: 10px 0;
    }
    table {
      td { padding: 1px 30px 1px 0px;}
    }
    #signature {
      margin-top: 2.5rem;
    }
    /*---- WITH LETTERHEAD ----*/
    #admin-wrapper.show-letterhead {
      padding: 20px 80px;
      .letterhead { display: block; img { opacity: 1; }}
      .letterhead.top {
        width: 100%;
        text-align: center;
        img { 
          height: 70px;
          width: auto;
          margin-bottom: 20px;
        }
      }
      .letterhead.bottom {
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        width: 100%;
        img {
          width: 195px;
          height: auto;
        }
        .line {
          width: 200px;
          margin: 0 auto;
          background-color: rgba(114,112,113,1);
          -webkit-print-color-adjust: exact;
          height: 1px;
          position: relative;
          margin-top: 5px;
        }
        p {
          font-size: 0.7rem;
          margin: 0;
          line-height: 1;
          margin-top: 5px;
          font-family: 'Helvetica Neue';
          color: rgba(114,112,113,1) !important;
          -webkit-print-color-adjust: exact;
          span {
            font-weight: 400;
          }
        }
        p.contact { margin-top: 15px; }

      }
    }
  }
}