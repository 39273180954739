$blue: #4679BD;
$blue-hover: #295BBF;

html.print-collection {
  #admin-wrapper #inner-wrapper { padding: 2rem;}
  .letterhead { display: none; }
  h4 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.5rem;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  button {
    background-color: $blue;
    color: white;
    border: none;
    -webkit-appearance: none;
    margin-left: 20px;
    position: relative;
    top: -2px;
    padding: 7px 20px;
    line-height: 1;
    &:hover { background-color: $blue-hover; }
  }

  table {
    margin: 1rem 0 3rem 0;
    td { font-weight: 300; line-height: 1.4;}
  }
  
  @media print {
    background-color: transparent;
    overflow: visible;
    height: auto; 
    width: 8.27in; 
    body {
      overflow: visible;
      background-color: transparent;
      height: auto;  
    }
    nav {
      display: none;
    }
    #admin-wrapper {
      padding: 0 0.5in;
      overflow-y:visible;
      height: auto;  
      margin: 0;
      #inner-wrapper { 
        height: auto;   
        padding: 0; 
        overflow-y:visible;
      }
      .page {
        height: 11.17in; 
        width: 100%;
        position: relative;
        margin: 0;
        padding: .5in 0 0 0;
        page-break-after: always;
        &:last-child{
          page-break-after: none;
        }
      }
      nav, h4.collection-title, button { display: none; }
      .letterhead { display: none; img { opacity: 1; }}
      .letterhead.top {
        width: 100%;
        text-align: center;
        img { 
          height: 90px;
          width: auto;
          margin-bottom: 20px;
        }
      }

      .img-wrapper {
        position: relative;
        text-align: center;
        // border: 1px solid black;
        img {
          width: auto;
          height: auto;
          margin: 1rem 0;
          max-width: 7in;
          max-height: 8.5in;
        }
      }

      table {
        margin: 0 auto;
        position: absolute;
        bottom: 180px;
        display:none;
        td { 
          font-size: 0.8rem; 
          font-weight: 300;
          line-height: 1.5;
        }
        tr td:first-child { 
          text-align: right;
          padding-right: 10px;
          width: 50%; 
        }
        tr td:last-child { 
          padding-left: 10px;
          width: 50%; 
        }
      }

      .print-info {
        position: absolute;
        bottom: 0.4in;
        left: 0px;
        p {
          font-size: 0.9rem;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          font-family: 'Helvetica Neue', Helvetica, sans-serif;
        }
        p.title {
          font-weight: 600;
        }
        p.artist {
          margin-bottom: 0.3rem;
        }
        p.medium, p.size, p.price {
          font-size: 0.8rem;
        }
        p.code {
          font-size: 0.7rem;
          font-weight: 500;
          font-color: gray;
          margin-top: 0.5rem;
        }
      }


      .letterhead.bottom {
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        img {
          width: 200px;
          height: auto;
        }
        .line {
          width: 200px;
          margin: 0 auto;
          background-color: rgba(114,112,113,1);
          -webkit-print-color-adjust: exact;
          height: 1px;
          position: relative;
          margin-top: 5px;
        }
        p {
          font-size: 0.7rem;
          margin: 0;
          line-height: 1;
          margin-top: 5px;
          font-family: 'Helvetica Neue';
          color: rgba(114,112,113,1) !important;
          -webkit-print-color-adjust: exact;
          span {
            font-weight: 400;
            font-size: 0.7rem;
            line-height: 1;
            font-family: 'Helvetica Neue';
          }
        }
        p.contact { margin-top: 15px; }

      }
    }
  }
}